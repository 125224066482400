<template>
  <v-container>
    <v-card class="home-filters pa-3" elevation="0" light>
      <v-row no-gutters class="justify-space-between">
        <v-col cols="12" md="3">
          <div class="d-flex home-filters-block mb-2 mb-md-0 pa-2">
            <div class="flex home-filters-item">
              <p class="text-caption grey--text mb-2">Location</p>
              <v-autocomplete
                v-if="settings.filter_by_region"
                flat
                attach="#my-element"
                v-model="region"
                :items="settings.regions"
                item-text="name"
                item-value="id"
                hide-no-data
                hide-details
                dense
                rounded
                placeholder="Select location"
              >
              </v-autocomplete>
              <v-autocomplete
                v-else
                flat
                attach="#my-element"
                v-model="city"
                :items="settings.cities"
                hide-no-data
                hide-details
                dense
                rounded
                placeholder="Select location"
              >
              </v-autocomplete>
              <div
                id="my-element"
                :class="{ 'p-absolute': !isMobile }"
                style="bottom: 26px"
              ></div>
            </div>
            <v-divider class="d-none d-md-block mr-4" vertical />
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <site-date-picker :value.sync="dateRange" is-range is-custom>
            <div class="d-flex home-filters-block mb-2 mb-md-0 pa-2">
              <div class="flex home-filters-item">
                <p class="text-caption grey--text mb-2">Check-in / Check-out</p>
                <div v-if="dateRange.startDate">
                  <div
                    class="d-md-none"
                    v-text="parseDate(dateRange.startDate, 'MMM DD, YYYY')"
                  />
                  <div class="d-none d-md-block">
                    <span
                      v-text="parseDate(dateRange.startDate, 'MMM DD, YYYY')"
                    />
                    &mdash;
                    <span
                      v-text="parseDate(dateRange.endDate, 'MMM DD, YYYY')"
                    />
                  </div>
                </div>
                <span class="grey--text" v-else>Select date...</span>
              </div>
              <v-divider class="d-md-none mx-4" vertical />
              <div class="flex home-filters-item d-md-none">
                <p class="text-caption grey--text mb-2">Check-out</p>
                <div
                  v-if="dateRange.endDate"
                  v-text="parseDate(dateRange.endDate, 'MMM DD, YYYY')"
                />
                <span class="grey--text" v-else>Select date...</span>
              </div>
              <v-btn
                v-if="dateRange.startDate"
                class="ml-2"
                icon
                small
                @click="clear"
              >
                <v-icon small> fa fa-times </v-icon>
              </v-btn>
            </div>
          </site-date-picker>
        </v-col>
        <v-col cols="12" md="4">
          <div class="d-flex home-filters-block mb-2 mb-md-0 pa-2">
            <v-divider class="d-none d-md-block mr-4" vertical />
            <div class="flex home-filters-item mr-4 mr-md-0">
              <p class="text-caption grey--text mb-2">Adults</p>
              <count-picker :value.sync="adults" />
            </div>
            <v-divider class="mx-4" vertical />
            <div class="flex home-filters-item">
              <p class="text-caption grey--text mb-2">Children</p>
              <count-picker :value.sync="children" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            :color="actionsColor"
            dark
            elevation="0"
            min-height="52"
            height="100%"
            block
            @click="search"
          >
            <v-icon small>$search</v-icon>
            <span class="text-capitalize ml-3">Search</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import FormattersMixin from "@/mixins/formatters-mixin";
import SiteDatePicker from "@/components/common/site-date-picker";
import CountPicker from "@/components/common/count-picker.vue";
import DeviceMixin from "@/mixins/device-mixin";
import { mapState } from "vuex";
import stylesMixin from "@/mixins/styles-mixin";

export default {
  name: "home-filters",
  components: { SiteDatePicker, CountPicker },
  mixins: [FormattersMixin, DeviceMixin, stylesMixin],
  data: () => ({
    city: null,
    region: null,
    dateRange: {
      startDate: null,
      endDate: null,
    },
    adults: 1,
    children: 0,
  }),
  mounted() {
    if (!this.isHotelSite) return;
    this.dateRange.startDate = this.$moment()
      .add(1, "day")
      .format("YYYY-MM-DD");
    this.dateRange.endDate = this.$moment().add(2, "day").format("YYYY-MM-DD");
  },
  computed: {
    ...mapState({
      settings: (state) => state.settingsModule.settings,
    }),
    isHotelSite() {
      return this.settings.site_type === "hotel" && !this.isMobile;
    },
  },
  watch: {
    "$route.query"() {
      this.dateRange = {
        startDate: this.$route.query.check_in,
        endDate: this.$route.query.check_out,
      };
    },
  },
  methods: {
    search() {
      const { startDate, endDate } = this.dateRange;
      const check_in =
        startDate && this.$moment(startDate).format("YYYY-MM-DD");
      const check_out = endDate && this.$moment(endDate).format("YYYY-MM-DD");

      const routeLocation = {
        name: "listings",
        query: {
          check_in,
          check_out,
          city: this.city,
          region: this.region,
          adults: this.adults,
          children: this.children,
        },
      };

      if (window.location.pathname.includes("/search-widget")) {
        const resolvedRoute = this.$router.resolve(routeLocation);
        window.open(resolvedRoute.href, "_blank");
      } else {
        this.$router.push(routeLocation);
      }
    },
    clear() {
      this.dateRange = { startDate: null, endDate: null };
    },
  },
};
</script>

<style scoped lang="scss">
.home-filters {
  &-block {
    border-radius: 5px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      border: 1px solid map-get($grey, "lighten-3");
    }
  }
}
::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 !important;
}
::v-deep .v-input__icon {
  display: none !important;
}
</style>
