<template>
  <div v-if="!isAgentSite" class="site-header">
    <v-app-bar
      app
      :flat="flat"
      :dark="dark"
      :color="dark ? 'transparent' : 'white'"
      class="site-header-bar"
      :height="siteHeaderHeight"
      :inverted-scroll="inverted"
    >
      <router-link class="header-router-link" to="/">
        <img
          v-if="!dark"
          :src="settings.logo"
          :width="logoWidth"
          class="logo-img pa-2 ps-0"
        />
      </router-link>
      <v-spacer />
      <!-- Removed listing-filters-bar from here since it's now in the main content -->
      <!-- <v-text-field
        v-else
        color="info"
        class="d-none d-sm-block"
        label="Search Location..."
        hide-details
        solo-inverted
        flat
        prepend-inner-icon="$search"
      /> -->
      <v-spacer v-if="filters" />
      <v-sheet v-if="!isMobile" color="transparent" class="pa-0">
        <!-- <v-btn text>
          <v-icon>far fa-heart</v-icon>
          <span v-show="!isMobileOrIPad" class="text-capitalize ml-2"
            >Wishlist</span
          >
        </v-btn> -->
        <v-btn @click="listingsSearch" text>
          <v-icon small :color="dark ? 'white' : primaryColor"
            >fa fa-search</v-icon
          >
          <span class="text-capitalize ml-2">Search</span>
        </v-btn>
        <v-btn :href="portalUrl" target="_blank" text>
          <v-icon small :color="dark ? 'white' : primaryColor"
            >far fa-user</v-icon
          >
          <span class="text-capitalize ml-2">Portal</span>
        </v-btn>
        <v-btn href="/" text>
          <v-icon small :color="dark ? 'white' : primaryColor"
            >fa fa-home</v-icon
          >
          <span class="text-capitalize ml-2">Home</span>
        </v-btn>
      </v-sheet>
      <!-- <listing-filters-dialog v-if="isMobile && filters" /> -->
      <!-- <template #extension v-if="filters">
        <v-container class="px-0" fluid>
          <v-row no-gutters>
            <v-col cols="12" sm="10" lg="11">
              <listing-tags />
            </v-col>
            <v-col cols="12" sm="2" lg="1" class="d-none d-sm-block text-right">
              <listing-filters-dialog v-if="!isMobile" />
            </v-col>
          </v-row>
        </v-container>
      </template> -->
      <!-- <v-icon>far fa-user</v-icon> -->
    </v-app-bar>
  </div>
</template>

<script>
import { LOGO, LOGO_WHITE } from "@/consts/CMS";
import DeviceMixin from "@/mixins/device-mixin";
import MediaMixin from "@/mixins/media-mixin";
import StylesMixin from "@/mixins/styles-mixin";
// import ListingFiltersDialog from "@/components/listing/listing-filters-dialog";
import { mapState } from "vuex";

export default {
  name: "site-header",
  components: {},
  mixins: [DeviceMixin, MediaMixin, StylesMixin],
  computed: {
    ...mapState({
      settings: (state) => state.settingsModule.settings,
    }),
    isAgentSite() {
      return this.settings.site_type === "agent";
    },
    portalUrl() {
      return this.settings.portal_url;
    },
  },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    listingsSearch() {
      this.$router.push({
        name: "listings",
        query: this.$route.query,
      });
    },
  },
  data: () => ({ LOGO, LOGO_WHITE }),
};
</script>

<style lang="scss">
.site-header-bar {
  &.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: 0px 1px 1px 0px map-get($grey, "lighten-3");
  }
}
.v-btn:before {
  opacity: 0 !important;
}

.v-ripple__container {
  opacity: 0 !important;
}
.logo-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.header-router-link {
  height: 100%;
}
</style>
